import { extendTailwindMerge, fromTheme, validators } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({
  prefix: 'tw-',
  extend: {
    classGroups: {
      '-translate-x': [{ '-translate-x': [validators.isArbitraryValue, fromTheme('spacing')] }],
      '-translate-y': [{ '-translate-y': [validators.isArbitraryValue, fromTheme('spacing')] }],
      animate: [
        {
          animate: [
            'slide-up',
            'reverse-slide-up',
            'slide-right',
            'reverse-slide-right',
            'slide-up-0.5',
            'reverse-slide-up-0.5',
            'slide-down-0.5',
            'reverse-slide-down-0.5',
            'slide-left-0.5',
            'reverse-slide-left-0.5',
            'slide-right-0.5',
            'reverse-slide-right-0.5',
            'splash-in-top',
            'splash-out-top',
            'splash-in-bottom',
            'splash-out-bottom',
            'splash-in-left',
            'splash-out-left',
            'splash-in-right',
            'splash-out-right',
            'fade-in',
            'fade-out',
            'zoom-in',
            'zoom-out',
            'dash',
            'border-blinking',
            'cursor-blinking',
            'scroll-x',
          ],
        },
      ],
      'bg-image': [
        { bg: ['none', 'checked-dark', 'checked-white', 'indeterminate-dark', 'indeterminate-white', 'checker'] },
      ],
      'bg-position': [{ bg: ['checker'] }],
      'bg-size': [{ bg: ['checker'] }],
      content: [{ content: ['empty'] }],
      'font-size': [
        {
          text: [
            '0',
            'marketing-sm',
            'marketing-md',
            'marketing-lg',
            'marketing-h-2xs',
            'marketing-h-xs',
            'marketing-h-sm',
            'marketing-h-md',
            'marketing-h-lg',
            'marketing-h-xl',
            'marketing-h-2xl',
          ],
        },
      ],
      transition: [{ transition: ['inset', 'padding', 'box', 'visibility'] }],
    },
    conflictingClassGroups: {
      'translate-x': ['-translate-x'],
      'translate-y': ['-translate-y'],
    },
  },
});

function resolveTwClasses(...classesAttrs) {
  const mergedClasses = customTwMerge(...classesAttrs);

  return mergedClasses;
}

export default resolveTwClasses;
